import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { ModeContext } from "../context/ModeContext";
import { CartContext } from "../context/CartContext";
import { Switch } from "@headlessui/react";
import { ShoppingCartIcon } from "@heroicons/react/outline";
// import { ShoppingCartIcon } from '@heroicons/react/solid'
import { cartCoinTotal } from "../utils/cart";

export default () => {
  const {
    user,
    setUser,
    create,
    setCreate,
    simpleUser,
    setSimpleUser,
    mainImages,
    setImages,
  } = useContext(UserContext);
  const { cart } = useContext(CartContext);
  const { mode } = useContext(ModeContext);
  const [enabled, setEnabled] = useState(mode);
  console.log("mode", mode);
  console.log("Navvy121", create);

  useEffect(() => {
    if (mode === null) {
      setEnabled(true);
    }
  }, []);

  const [navbar, setNavbar] = useState(false);

  return (
    <>
      <div className="menu-phone max-w-screen-xl flex flex-wrap items-center justify-between Nav sectWidth mt-8 mx-auto relative z-30">
        <button
          className={`xl:hidden menu-button p-2  rounded-md outline-none focus:border-gray-400 focus:border ${
            create === "darkbg" ? "text-white" : "text-gray-700"
          }`}
          onClick={() => setNavbar(!navbar)}
        >
          {navbar ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </button>

        <Link className="logoBox" to="/home">
          {mainImages && (
            <img
              className="w-100 man-logo"
              alt="REN logo"
              src={
                create === "darkbg"
                  ? mainImages[0]?.lightlogo?.url
                  : mainImages[0]?.darklogo?.url
              }
            />
          )}
        </Link>
        <div className="ml-20">
          <Switch.Group
            as="div"
            className="flex text-gray-600 justify-center space-x-4 mx-auto text-2xl sm:text-4xl lg:text-xl items-center"
          >
            <Switch.Label className="norm text-xl">
              {create === "light" && (
                <img className="w-100 vector" alt="REN logo" src="../sun.png" />
              )}
              {create === "darkbg" && (
                <img
                  className="w-100 vector"
                  alt="REN logo"
                  src="../moon.png"
                />
              )}
            </Switch.Label>
            <Switch
              as="button"
              checked={enabled}
              onChange={setEnabled}
              className={`${
                enabled ? "bg-white" : "bg-white"
              } relative inline-flex flex-shrink-0 tog duration-200 ease-in-out border-2 border-solid border-black rounded-full cursor-pointer focus:outline-none focus:shadow-outline`}
            >
              {({ checked }) => (
                <span
                  className={`${
                    checked ? "translate-x-3" : "-translate-x-1"
                  } inline-block togBtn transition duration-200 ease-in-out transform  border-solid  border-black border-2 rounded-full`}
                />
              )}
            </Switch>
          </Switch.Group>

          {enabled
            ? (document.body.classList.remove("darkbg"),
              localStorage.setItem("lightMode", true),
              setCreate("light"))
            : (document.body.classList.add("darkbg"),
              localStorage.setItem("lightMode", false),
              setCreate("darkbg"))}
        </div>

        <div
          className={`flex-1 justify-self-center pb-3 mt-8 show-menu-bar lg:pb-0 lg:mt-0 ${
            navbar ? "block" : "hidden"
          }`}
        >
          <div
            className={`colsaps-nav flex flex-col p-4 md:p-0 mt-4   rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white xl:mr-10 dark:bg-gray-900 md:dark:bg-gray-900 dark:border-gray-900Nav justify-end  ${
              create === "darkbg" ? "darkBx" : ""
            }`}
          >
            <Link
              onClick={() => setNavbar(false)}
              to="/search"
              className={
                create === "darkbg"
                  ? "mx-4 menuItem text-white"
                  : "mx-4 menuItem"
              }
            >
              Browse
            </Link>

            <Link
              onClick={() => setNavbar(false)}
              to="/credits"
              className={
                create === "darkbg"
                  ? "mx-4 menuItem text-white"
                  : "mx-4 menuItem"
              }
            >
              R.E.N Credits
            </Link>

            <Link
              onClick={() => setNavbar(false)}
              to="/about"
              className={
                create === "darkbg"
                  ? "mx-4 menuItem text-white"
                  : "mx-4 menuItem"
              }
            >
              About us
            </Link>

            {/* <Link
              onClick={() => setNavbar(false)}
              to="/partners"
              className={
                create === "darkbg"
                  ? "mx-4 menuItem text-white"
                  : "mx-4 menuItem"
              }
            >
              Partner with us
            </Link> */}
            <Link
              onClick={() => setNavbar(false)}
              to="/type"
              className={
                create === "darkbg"
                  ? "mx-4 menuItem text-white"
                  : "mx-4 menuItem"
              }
            >
              Powered by R.E.N
            </Link>

            {/*      {user && 
        <Link to='/newlisting' className={create === 'darkbg' ? "mx-6 menuItem text-white" : "mx-6 menuItem"}>Add Listing</Link>
      }*/}
            <Link
              onClick={() => setNavbar(false)}
              to="/contact"
              className={
                create === "darkbg"
                  ? "mx-4 menuItem text-white"
                  : "mx-4 menuItem"
              }
            >
              Contact
            </Link>
            {user && (
              <>
                <div
                  onClick={() =>
                    (window.location.href = `/profile/${user.user.id}`)
                  }
                  className={
                    create === "darkbg"
                      ? "ml-4 mr-6 text-white menuItem cursor-pointer"
                      : "ml-4 mr-6 menuItem cursor-pointer"
                  }
                >
                  {" "}
                  My Profile{" "}
                </div>
                <Link
                  to="/home"
                  className={
                    create === "darkbg" ? "authBtn text-white" : "authBtn"
                  }
                  style={{ padding: "0.3rem" }}
                  onClick={() => {
                    window.location.href = "/home";
                    setUser(null);
                    setSimpleUser(null);
                    localStorage.removeItem("user");
                    localStorage.removeItem("simpleUser");
                    setNavbar(false);
                    localStorage.removeItem("cart");
                  }}
                >
                  Log Out
                </Link>
                {simpleUser && (
                  <div className="flex flex-row items-center mx-4">
                    <div className="w-6 -mt-1 mr-1">
                      <img src="../coin.png" alt="coin" />
                    </div>
                    <div
                      className={
                        create === "darkbg"
                          ? "menuItem text-white"
                          : " menuItem"
                      }
                    >
                      {simpleUser.coins}
                    </div>
                  </div>
                )}
                {cart && cart.length > 0 && (
                  <Link onClick={() => setNavbar(false)} to="/cart">
                    <div className="flex flex-row items-center">
                      <ShoppingCartIcon
                        className={
                          create === "darkbg"
                            ? "h-6 w-6 text-white"
                            : "h-6 w-6 text-black"
                        }
                        aria-hidden="true"
                      />

                      <div
                        className={
                          create === "darkbg"
                            ? "pt-1 menuItem text-white"
                            : "pt-1 menuItem"
                        }
                      >
                        {cartCoinTotal(cart)}
                      </div>
                    </div>
                  </Link>
                )}
              </>
            )}
            {!user && (
              <>
                <NavLink
                  onClick={() => setNavbar(false)}
                  className={
                    create === "darkbg"
                      ? "text-white mx-4 menuItem"
                      : "mx-4 menuItem"
                  }
                  to="/login"
                  exact
                >
                  {" "}
                  Login{" "}
                </NavLink>
                <NavLink
                  onClick={() => setNavbar(false)}
                  className={
                    create === "darkbg"
                      ? "text-white mx-4 menuItem"
                      : "mx-4 menuItem"
                  }
                  to="/signup"
                  exact
                >
                  {" "}
                  Signup{" "}
                </NavLink>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
